body{
    color: $black-color;
    font-size: $font-size-base;
    font-family: $sans-serif-family;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.main{
    position: relative;
    background: $white-color;
}
/* Animations */
.nav-pills .nav-link,
.nav-item .nav-link,
.navbar,
.nav-tabs .nav-link,
.tag,
.tag [data-role="remove"]{
    @include transition($general-transition-time, $transition-ease);
}

.bold {
    font-weight: 1000;
    color: black;
    &.red {
        color: red;
    }
    &.blue {
        color: blue;
    }
    &.green {
        color: green;
    }
    &.orange {
        color: orange;
    }
    &.purple {
        color: purple;
    }
    &.brown {
        color: brown;
    }

    &.italic {
        font-style: italic;
        color: rgb(117, 117, 117);
        padding-right: 3px;
    }
}

p.card-text {
    font-weight: 400;
}

.category {
    text-transform: none;
}

h5.description {
    font-weight: 500;
}

.recap-tag {
    background-color: black;
    color: white;
    font-size: 1.4em;   
    margin: 0 4px; 
}

.team-name-tag {
    background-color: black;
    color: white;   
    // color: orange;
}

.warning-tag {
    background-color: red;
    color: black;   
}

.winner-tag {
    background-color: green;
    // background-color: orange;
    color: white;  
    // color: black;  
    border-radius: 20px;
}

.loser-tag {
    background-color: red;
    // background-color: black;
    color: white;
    border-radius: 20px;
}

.trophy-font {
    font-family: Impact, Charcoal, sans-serif;
    font-size: 61px;
    letter-spacing: 1.8px;
    word-spacing: 1.8px;
    color: #FFFFFF;
    font-weight: 700;
    text-decoration: none;
    font-style: italic;
    font-variant: small-caps;
    text-transform: none;
    text-shadow: 2px 2px #ff9c00;
    margin:0;
    padding:0;
    
    }

//transition for dropdown caret
.card a,
.bootstrap-switch-label:before{
    @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle:after,
[data-toggle="collapse"][data-parent="#accordion"] i{
  @include transition-on-prop(transform, $fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
[data-toggle="collapse"][data-parent="#accordion"][aria-expanded="true"] i{
    @include rotate-180();
}

.button-bar{
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: $white-bg;

    & + .button-bar{
        margin-top: 7px;
    }

    &:nth-child(2){
        width: 17px;
    }
}

.separator-line{
    height: 2px;
    width: 44px;
    background-color: $default-color;
    margin: 20px auto;

    &.separator-primary{
        background-color: $primary-color;
    }
}

.section-space{
    height: 62px;
    display: block;
}

.pull-left{
  float: left;
}
.pull-right{
  float: right;
}

.title-up{
  text-transform: uppercase;
}

.fade-top {
    box-shadow: 0 0 25px 25px black;
    margin-top: -3vh;
}

.footer {
    padding-top: 35px !important;
}

[data-background-color="extra-dark"] {
    background-color: #000000 !important;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }